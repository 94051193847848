import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import indexPageStyles from "./index-page.module.scss"
import Head from "../components/head"
import Img from "gatsby-image"
import Pagination from "../components/pagination"

const IndexPage = ({ data, pageContext: { numPages, currentPage } }) => {
  return (
    <Layout>
      <Head
        title="Home"
        descriptionContent="SilverGoldPill.com is a blog dedicated to everything related to silver and gold coins and bullion. SilverGoldPill.com covers Market trends and news in the precious metal community."
      />

      <ol className={indexPageStyles.posts}>
        {data.allMdx.edges.map((edge, index) => {
          return (
            <li className={indexPageStyles.post} key={index}>
              <Link to={`/blog/${edge.node.fields.slug}`}>
                <Img
                  fluid={edge.node.frontmatter.image.childImageSharp.fluid}
                  alt={edge.node.frontmatter.imageDescription}
                />

                <h3 className={indexPageStyles.slugH3}>
                  {edge.node.frontmatter.title}
                </h3>
                <p className={indexPageStyles.slugSentence}>
                  {edge.node.frontmatter.sentence}
                </p>
                <div className={indexPageStyles.divLine}></div>
                <p className={indexPageStyles.slugDate}>
                  {edge.node.frontmatter.date}
                </p>
              </Link>
            </li>
          )
        })}
      </ol>

      <Pagination numPages={numPages} currentPage={currentPage} />
    </Layout>
  )
}

export const postsQuery = graphql`
  query postsQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            imageDescription
            title
            date(formatString: "MMMM Do, YYYY")
            sentence
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default IndexPage
